// Boostrap
@import "../node_modules/bootstrap/scss/bootstrap";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --brand-color: #f03d27;
    --brand-color-light: #ff452a;
}



// Document

html,
body,
#root,
.App {
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}


// Elements

body {
    background: #f7f7f7;
}

p {
    margin-bottom: 10px;
    line-height: 1.4;
}

a {
    color: #237bfd;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.hidden {
    display: none !important;
}

.no-break {
    white-space: nowrap;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;

    &-left {
        padding-left: 0;
    }
}

.padding {
    &-right-only {
        padding-left: 0;
        padding-right: 43px;
    }

    &-top-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}


.vertically-centered {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// Loading

.loading {
    width: 60px;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;

    .spinner {
        border: 5px solid #dcdcdc;
        border-top: 5px solid var(--brand-color);
        border-bottom: 5px solid var(--brand-color);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 0.8s linear infinite;
    }
    &.small {
        width: 20px;
        height: 30px;
        margin-left: -10px;
        margin-top: -10px;
        .spinner {
            border: 2px solid #dcdcdc;
            border-top: 2px solid var(--brand-color);
            border-bottom: 2px solid var(--brand-color);
            width: 20px;
            height: 20px;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-retry {
    width: 200px;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -50px;
    color: #777;
    background: #fff;
    padding: 20px;
}


// Pages

.dashboard {
    .dashboard-header {
        padding: 10px 2px;

        p {
            font-size: 13px;
        }

        h1 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .help-link {
            float: right;
        }
    }
}

// Buttons

.btn {
    font-size: 12px;
    @media (max-width: 500px) {
        font-size: 10px;
    }
    &.full-width {
        width: 100%;
        max-width: 100%;
    }
}

.btn-primary {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    color: #333;
    padding: 0 6.9px;
    height: 25px;
    line-height: 23px;
    border-radius: 2px;
    min-width: 34.2px;
    white-space: nowrap;

    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        color: #fff;
        background-color: var(--brand-color);
        border-color: var(--brand-color);
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        background-color: #fff;
        border-color: #d3d3d3;
        color: #333;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    }
}

.btn-secondary {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    color: #333;

    &.focus,
    &:focus {
        background-color: #fff;
        border-color: #d3d3d3;
        color: #333;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    }
}

.btn-action {
    background-color: var(--brand-color);
    border-color: var(--brand-color);
    color: #fff;
    padding: 0 6.9px;
    height: 25px;
    line-height: 23px;
    border-radius: 2px;
    min-width: 34.2px;
    white-space: nowrap;

    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: var(--brand-color-light);
        border-color: var(--brand-color-light);
        color: #fff;
    }

    &.disabled,
    &:disabled {
        background-color: #ccc;
        border-color: #ccc;
        color: #fff;
    }
}

.btn-action-alt {
    background-color: #e7e7e7;
    border-color:  #d3d3d3;
    color: #000;
    padding: 0 6.9px;
    height: 25px;
    line-height: 23px;
    border-radius: 2px;
    min-width: 34.2px;
    white-space: nowrap;
    margin-right: 15px;

    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: #eaeaea;
        border-color: #d6d6d6;
        color: #111;
    }

    &.disabled,
    &:disabled {
        background-color: #ccc;
        border-color: #ccc;
        color: #fff;
    }
}

.back-btn {
    padding-left: 0;
    padding-right: 0;

    &::before {
        content: "\2190\a0";
    }
}

// Help Link

.help-link {
    background: var(--brand-color);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    line-height: 35px;
    position: relative;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    color: #fff;

    &:hover {
        background: var(--brand-color-light);
        text-decoration: none;
        color: #fff;
    }
}

// Dropdown Menus

.dropdown-menu {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);

    .dropdown-divider {
        border-top: 1px solid #ccc;
    }
}

// Modals

.modal-footer.custom {
    position: relative;
    margin: 0 -10px -10px -10px;
}
.modal-header.custom {
    margin: -10px -10px 0 -10px;
    display: block;
}
.modal-content .content.custom {
    padding: 20px;
    position: relative;
}

/* Toggle Switches */
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--brand-color);
    background-color: var(--brand-color);
}

/* Alerts */
.alert {
    margin-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    box-shadow: 0px -1px 3px 3px rgba(0,0,0,0.1);
    .close {
        top: 2px;
    }
    p {
        margin: 0;
        padding: 4px 0;
    }
}

/* Accordion */
.accordion {
    margin-bottom: 20px;
}

.accordion .card {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    overflow: visible;
    border-radius: 2px;
}

.accordion .card .card-header {
    margin: 0;
    padding: 6px 10px;
}

.accordion .accordion-toggle {
    padding-left: 0;
    padding-right: 0;
    font-weight: bold;
    color: #000;
}

.accordion .title {
    text-transform: capitalize;
}

.accordion-content .row {
    padding: 12px 0;
    border-bottom: 1px solid #eee;
}

.accordion-content .row:first-child {
    padding-top: 0;
}

.accordion-content .row:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.accordion .card-header .add-domain-btn {
    float: right;
    margin-top: 2px;
}

.accordion .card-header .set-primary-offer-btn {
    float: right;
    margin-left: 3px;
    margin-right: 15px;
    margin-top: 2px;
}

.accordion .configure-btn {
    float: right;
    margin: 0 5px 2px 5px;

}

.accordion .test-btn {
    float: right;
    margin: 0 0 2px 5px;
}

.accordion .toggle {
    width: 25px;
    height: 25px;
    background: transparent;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    position: absolute;
    top: 2px;
    right: 5px;
}

.accordion .toggle::before {
    content: '\a0';
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve"><polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093"/></svg>');
    position: absolute;
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    margin: -5px 0 0 -5px;
}

.accordion .toggle.expanded {
    transform: rotate(180deg);
    transition: transform 300ms ease-in-out;
}

.accordion .toggle.collapsed {
    transform: rotate(0deg);
    transition: transform 300ms ease-in-out;
}

.accordion-content .domain-listing {
    height: 30px;
    &.pending {
        color: #ccc;
    }
    &.no-domains {
        color: #ccc;
    }
}

.accordion-content .domain-name {
    display: inline-block;
}


/* Modals - All */
.modal-title .subtitle {
    font-size: 12px;
    font-width: normal;
}

/* Modals - Test */
.modal-test {
    .modal-body {
        padding: 20px 35px;
        .resources {
            border: 1px solid #dedede;
            padding: 10px 15px;
            margin: 0 0 20px 0;
            background: #f2f2f2;
            color: #555;
            .link {
                font-size: 10px;
                font-family: monospace;
                display: block;
                color: #0d7bff;
                cursor: pointer;
                margin: 0;
                overflow-wrap: break-word;
                &::after {
                    content: '\a0\a0';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230d7bff'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none' /%3E%3Cpath d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    height: 24px;
                    width: 24px;
                    margin-left: 5px;
                }
            }
        }
    }
}

/* Modals - Add Domain */
.modal-configure {
    min-width: 100px;
    min-height: 100px;
}
.modal-configure .domain-display {
    padding: 15px 0 0 0;
    line-height: 1;
}

.modal-configure #offer-select,
.modal-configure #platform-select{
    width: 66.6666%;
}

.modal-configure .description .step-title {
    margin-bottom: 15px;
}

.modal-configure .description p {
    margin-bottom: 15px;
}

.modal-configure .description li {
    margin-bottom: 15px;
    position: relative;
}

.modal-configure .description li:before {
    content: '\25B6';
    font-size: 8px;
    color: #aaa;
    position: relative;
    top: -1px;
    left: -8px;
    margin-right: -5px;
}

.modal-configure .description p:last-child,
.modal-configure .description li:last-child {
    margin-bottom: 0px;
}

.modal-configure .description .btn {
    margin-left: 5px;
    margin-right: 5px;
}

.modal-configure .description + form {
    margin-top: 10px;
}

.modal-configure .description .screenshots {
    display: flex;
    margin: 25px -20px 10px -20px;
    min-height: 100px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.modal-configure .description .screenshots .capture {
    flex: 1;
    text-align: center;
    padding: 0 10px;
}

.modal-configure .description .screenshots .capture img {
    margin: 0 auto;
    border: 1px solid #bbb;
    padding: 3px;
    max-width: 250px;
}


.modal-configure [data-configuration-phase="verify"] [data-step="4"] .description .screenshots {
    margin: 15px -20px 15px -20px;
}
.modal-configure [data-configuration-phase="verify"] [data-step="4"] .capture img {
    max-width: 350px;
}





.modal-configure .footnote {
    padding: 0 5px;
    font-size: 12px;
    line-height: 24px;
    height: 25px;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 5px;
}

.modal-configure .step-display {
    color: var(--brand-color);
    top: 11px;
}


.modal-configure.loading-state,
.modal-configure.loading-state .modal-body {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.modal-configure.loading-state .modal-header,
.modal-configure.loading-state .modal-footer,
.modal-configure.loading-state .content,
.modal-configure.loading-state button.close {
    display: none;
}

.modal-configure.loading-state .modal-dialog {
    max-width: 200px;
}
.modal-configure.loading-state .modal-body {
    padding: 50px 0;
}
.modal-configure.loading-state .loading {
    width: 40px;
    margin-left: -20px;
    margin-top: -20px;
    top: 40%;
}
.modal-configure.loading-state .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ddd;
    border-top: 4px solid #999;
    border-bottom: 4px solid #999;
    animation: spin 0.99s linear infinite
}

.modal-configure.loading-state .message {
    text-align: center;
    padding: 20px;
    color: #888;
    line-height: 1.4;
    justify-content: normal;
    font-size: 12px;
}


.modal-configure .modal-title {
    padding: 2px 0px;
    line-height: 1.2;
}

.modal-configure .modal-title .fa {
    margin-right: 10px;
}

.modal-configure .modal-title.error .fa {
    color: #e53935;
}

.modal-configure .modal-title.success .fa {
    color: #5cb85c;
}

.modal-configure .btn-action {
    min-width: 75px;
}


.modal-configure .info {
    font-size: 12px;
    margin-bottom: 15px;
}

.modal-configure .message .heading {
    display: block;
    margin-bottom: 10px;
}

.modal-configure .message .body {
    color: #777;
    margin-bottom: 0;
}

.modal-configure .resources {
    border: 1px solid #dedede;
    padding: 10px 15px;
    margin: 0 0 20px 0;
    background: #f2f2f2;
    color: #555;
}

.modal-configure .resources .heading {
    display: block;
    margin-bottom: 10px;
}

.modal-configure .resources .link {
    font-size: 10px;
    font-family: monospace;
    display: block;
    color: #0d7bff;
    cursor: pointer;
}

.modal-configure .resources .link::after {
    content: '\a0\a0';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230d7bff'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none' /%3E%3Cpath d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    margin-left: 5px;
}

.modal-configure .resources .body {
    font-size: 10px;
    margin-bottom: 0;
}


/* Modals - QR Code */
@media (min-width: 576px) {
    .modal-qr-code .modal-sm {
        max-width: 250px;
    }
}

.modal-qr-code .modal-header {
    border: 0;
}

.modal-qr-code .modal-body {
    text-align: center;
    padding: 0 20px;
}

.modal-qr-code .modal-body #qrcode {
    margin: 0 auto;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) inset;
}

.modal-qr-code .modal-footer {
    border: 0;
    text-align: center;
    padding: 20px 0 50px 0;
}

.modal-qr-code .modal-footer .btn {
    margin: 0 auto;
}

label .example {
    display: inline-block;
    margin-left: 15px;
}

.input-container {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    background: #fff;
    margin-bottom: 25px;
    height: 40px;
    line-height: 40px;
    max-width: 550px;
}

.input-container input {
    border: 0;
    border-radius: 0;
    background: transparent;
    position: relative;
    z-index: 1;
    height: 38px;
    line-height: 40px;
    padding-right: 35px;
}

.validation-container {
    color: #dc3545;
    line-height: 1.2;
    width: 100%;
    font-size: 11px;
    display: none;
    margin-top: -25px;
    padding: 5px 0 10px 0;

    &.invalid {
        display: flex;

        .field-0 {
            padding: 0 0 0 10px;
        }
        .field-1 {
            padding: 0 0 0 10px;
            flex: 0 0 45%;
            max-width: 45%;
        }
        .field-2 {
            padding: 0 0 0 20px;
            flex: 0 0 55%;
            max-width: 55%;
        }
    }
}



//
//.input-container .validation.error {
//    display: block;
//    line-height: 1.2;
//}

.input-group {
    flex: 1;
    position: relative;

    .leading-indicator {
        display: inline-block;
        padding: 0 0 0 10px;
        margin-top: -1px;
        user-select: none;
    }
}

.input-group button {
    height: 38px;
    line-height: 38px;
    width: 75px;
    border-radius: 0 2px 2px 0;
    box-shadow: none;
}

.input-group .infodot {
    position: absolute;
    top: 10px;
    right: 8px;
    width: 18px;
    height: 18px;
    min-width: 0;
    line-height: 15px;
    border: 2px solid #999;
    border-radius: 50%;
    z-index: 10;
    text-align: center;
    padding: 0;
    font-weight: bold;
    color: #999;
    font-size: 12px;
    font-family: sans-serif;
    background: #fff;
}

.input-group .infodot:hover {
    border: 2px solid var(--brand-color);
    color: var(--brand-color);
}


.input-group[data-validator="currency"]:before {
    content: '$';
    position: absolute;
    top: 0px;
    left: 5px;
    z-index: 10;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 10px;
    color: #bbb;
}

.input-group[data-validator="currency"] input {
    padding-left: 35px;
}

.platform-google .input-group:nth-child(1) {
    flex: 0 0 45%;
    max-width: 45%;
}
.platform-google .input-group:nth-child(1) input {
    padding-right: 25px;
    padding-left: 10px;
}
//
//.platform-google .input-group:nth-child(1) .validation {
//    padding-left: 10px;
//}
//
//.platform-google .input-group:nth-child(1)::before {
//    content: 'AW-';
//    position: absolute;
//    top: 0px;
//    left: 5px;
//    z-index: 10;
//    height: 40px;
//    line-height: 40px;
//    padding: 0 0 0 10px;
//}

.platform-google .input-group:nth-child(2) {
    flex: 0 0 55%;
    max-width: 55%;
}
.platform-google .input-group:nth-child(2) input {
    padding-left: 25px;
    padding-right: 25px;
    background: transparent;

}

.platform-google .input-group:nth-child(2) .validation {
    padding-left: 15px;
    background: transparent;
}

.platform-google .input-group:nth-child(2):before {
    content: '/';
    position: absolute;
    top: 1px;
    left: 0px;
    z-index: 10;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 10px;
}

.platform-tiktok .input-group:nth-child(1) input,
.platform-tiktok .input-group:nth-child(1) .validation {
}

.platform-snapchat .input-group:nth-child(1) input,
.platform-snapchat .input-group:nth-child(1) .validation {
}

/* Tracking Panel */
.tracking-panel label {
    margin-bottom: 2px;
}
.tracking-panel input[type="text"],
.tracking-panel select{
    margin-bottom: 10px;
}

.tracking-panel .link-display {
    background: #f7f7f7;
    border: 1px solid #dddddd;
    position: relative;
    min-height: 50px;
    padding: 20px 15px;
    font-size: 10px;
    margin-top: 15px;
    font-size: 12px;
}

.tracking-panel .link-display .actions {
    position: absolute;
    top: 3px;
    right: 2px;
    display: flex;
}

.tracking-panel .link-display .actions .qr-code,
.tracking-panel .link-display .actions .copy-link {
    height: 18px;
    width: 18px;
    margin: 0 0 0 1px;
}

/* Verification Flow */
.dns-form .status-message {
    display: none;
}
.dns-form[data-form-status] .form-fields,
.dns-form[data-form-status] .status-message {
    display: none;
}
.dns-form[data-form-status="ready"] .form-fields,
.dns-form[data-form-status="processing"] .processing,
.dns-form[data-form-status="success"] .success,
.dns-form[data-form-status="failure"] .failure {
    display: block;
}

.dns-form[data-form-status] .status-message {
    .fa {
        margin-right: 10px;
    }
    .message::first-letter {
        text-transform: capitalize;
    }
}


.dns-form[data-form-status="processing"] .processing {
    border: 1px solid #dedede;
    padding: 10px 15px;
    margin: 0 -10px;
    background: #f2f2f2;
    color: #777;
}

.dns-form[data-form-status="success"] .success {
    border: 1px solid #badbcc;
    padding: 10px 15px;
    margin: 0 -10px;
    background: #d0e7dd;
    color: #316c50;
}

.dns-form[data-form-status="failure"] .failure {
    border: 1px solid #f5c2c7;
    padding: 10px 15px;
    margin: 0 -10px;
    background: #f7d7da;
    color: #8e3139;

    .try-again {
        text-decoration: underline;
        color: #ff5454;
        cursor: pointer;
    }
}



.dns-form .validation {
    color: #dc3545;
    height: 20px;
    line-height: 1;
    width: 100%;
    font-size: 10px;
    display: none;
}

.dns-form .validation.error {
    display: block;
    line-height: 1.2;
}


.modal-configure .error-tips {
    border: 1px solid #ddd;
    background: #f2f2f2;
    padding: 10px 15px;
    margin: 10px 0;
    font-size: 11px;
}

.modal-configure .error-tips p,
.modal-configure .error-tips strong {
    margin-bottom: 10px;
    display: block;
}